<template>
  <section  class="uk-section uk-section-secondary uk-section-large">
    <div class="uk-container">
      <h2 class="uk-light uk-text-center">Mi cuenta Cannon</h2>

      <h3 class="uk-light">Licencias</h3>

      <p>Estas son las licencias ligadas a tu cuenta</p>

      <div v-if="!loaded">
        <p class="uk-text-center"><span uk-spinner="ratio:4"></span></p>
      </div>

      <div v-else-if="licences" uk-grid class="uk-child-width-1-2@s">
          <div v-for="item, index in licences">
            
            <div v-if="info[index]" class="uk-card uk-card-secondary uk-card-hover uk-card-body uk-border-rounded" style="background-color:#903396">
                <h3 class="uk-card-title">{{info[index].name}}</h3>
                <p>{{info[index].desc}}</p>

                <p v-if="item.usercodes"  style="background-color:black">
                  Los códigos para registrar usuarios son: 
                  <table class="uk-table uk-table-hover uk-table-small uk-table-striped uk-table-responsive">
                    <thead>
                      <tr>
                        <th>
                          Sucursal
                        </th>
                        <th>
                          Código Admin
                        </th>
                        <th>
                          Código Usuario
                        </th>
                      </tr>
                    </thead>
                    <tr v-for="data,id in item.usercodes">
                      <td>
                        {{data.name}}
                      </td>
                      <td>
                        {{data.admin}}
                      </td>
                      <td>
                        {{data.user}}
                      </td>
                    </tr>
                  </table>

                  <br>
                  Tus usuarios deberán ingresar el código al crear su cuenta de Cannonsoft o con el botón ACTIVAR LICENCIA en la página MI CUENTA de cada uno. 
                </p>

                <div uk-grid class="uk-child-width-expand">
                  <div>
                    <a :href="info[index].link"  class="uk-button uk-margin uk-button-secondary uk-border-pill" >Ir a la app</a>
                  </div>

                </div>

            </div>
          </div>
      </div>

      <div v-else>
        <h2>No hay licencias ligadas a esta cuenta</h2>
      </div>

      <hr>

      

        <div uk-grid class="uk-child-width-1-3@s uk-grid-large"> 
          <div>
            <a class="uk-button uk-button-large uk-button-secondary uk-border-pill" href="#modal-sections" uk-toggle>Activar Licencia</a>
          </div> 
          <div>
            <router-link to="/cotizacion"  class="uk-button uk-button-large uk-button-primary uk-border-pill">Cotiza tu servicio</router-link>
          </div>
          <div>
            <router-link to="/services" class="uk-button uk-button-large uk-button-default uk-border-pill">Servicios de Cannonsoft</router-link>
          </div> 
        </div>


    </div>
  </section>

<div id="modal-sections" class=" uk-light" uk-modal>
    <div class="uk-modal-dialog uk-background-secondary">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header uk-background-secondary">
            <h2 class="uk-modal-title">Activar licencia</h2>
        </div>
        <div class="uk-modal-body">
            <p>Activa una licencia para un servicio o producto de Cannonsoft. <br> Ingresa tu código:</p>
            <input v-model="code" type="password" class="uk-input uk-form-width-medum" placeholder="Código">
        </div>
        <div class="uk-modal-footer uk-text-right uk-background-secondary">
            <button class="uk-button uk-button-default uk-modal-close uk-border-pill" type="button">Cancelar</button> &nbsp;
            <button v-if="loaded" @click="activate" class="uk-button uk-button-primary uk-border-pill" type="button">Activar</button>
            <span uk-spinner v-else></span>
        </div>
    </div>
</div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
export default {
  setup(){

    const info = ref({})
    firebase.database().ref('site/info').once('value').then((s)=>{
      info.value = s.toJSON()
    })

    const loaded = ref(false)
    const licences = ref({})
    const router = useRouter()
    const uid = ref(null)

    onMounted(()=>{
                window.scroll(0,0)
      loaded.value = false
      if(firebase.auth().currentUser === null){
        // UIkit.notification('Not logged')
        router.push('/login')
        return
      } 
      var user = firebase.auth().currentUser
      uid.value = user.uid
      firebase.database().ref('users/'+uid.value+'/licences').on('value', (s)=>{
        if(s.exists()){
          licences.value = s.toJSON()
        } else {
          licences.value = false
        }
        loaded.value = true
      })

    })


    const code = ref('')
    const activate = ()=>{
      if(!code.value) {
        UIkit.notification('El código ingresado es inválido','danger')
        return
      }
      loaded.value = false
      firebase.database().ref('site/codes/'+code.value).once('value').then(async (s)=>{
        if(s.exists()){
          let data = s.toJSON()
          let uid = firebase.auth().currentUser.uid
          await firebase.database().ref('users/'+uid+'/licences/').update(data)
          UIkit.notification('Licencia activada correctamente','success')
          UIkit.modal(document.getElementById('modal-sections')).hide()
          loaded.value = true
        } else {
          UIkit.notification('El código ingresado es inválido','danger')
          loaded.value = true
        }
      })
    }

    
    return {
      licences,
      loaded,
      info,
      activate,
      code,
    }
  }
}
</script>

<style>

</style>